@import url(https://fonts.googleapis.com/css?family=Exo+2:200i);

@font-face {
  font-family: Neon;
  src: local('Neon'), url(../fonts/Neon.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  margin-bottom: 0 0 60px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

