@import url(https://fonts.googleapis.com/css?family=Exo+2:200i);
.App {
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: scroll;
  text-align: center;
}

.Nav {
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.Nav li {
  padding: 20px 0;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 30px;
  color: #fff;
  float: left;
  cursor: pointer;
}

.Nav a {
  color: #fff;
  text-decoration: none;
  outline: 0;
}

.Nav a:visited {
  color: #fff;
}

.Nav a:active, .Nav a:hover, .Nav a:focus {
  color: #888;
}

.Nav button {
  text-align: left;
  font-size: 30px;
  margin-left: 30px;
  color: #fff;
  outline: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  float: left;
}

.Nav button:after {
  float: clear;
}

.Nav .links {
  text-align: left;
  display: none;
  list-style-type: none;
}

.Nav .show {
  display: block;
  height: 100px;
}

@media screen and (min-width: 878px) {
    .Nav button {
        display: none;
    }

    .Nav .links {
        display: flex;
        overflow: hidden;
    }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-container {
  scroll-snap-align: start;
  -ms-scroll-snap-type: mandatory;
      scroll-snap-type: mandatory;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-shadow:
      -0.3rem -0.3rem 1rem #000,
      0.3rem 0.3rem 1rem #000;
  color: #fff;
}

.App-content hr {
  border: 2px solid #fff;
  border-radius: 2px;
  text-shadow:
      -0.3rem -0.3rem 1rem #000,
      0.3rem 0.3rem 1rem #000;
}

.App-page {
  scroll-snap-align: none;
  -ms-scroll-snap-type: proximity;
      scroll-snap-type: proximity;
  scroll-padding: 110px 0 0;
  margin-top: 110px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-shadow:
      -0.3rem -0.3rem 1rem #000,
      0.3rem 0.3rem 1rem #000;
   color: #fff;
}

.App-link {
  color: #61dafb;
}

.App-neon {
  font-family: 'Neon';
  font-size: calc(10px + 11.5vmin);
  overflow: fixed;
  color: #eef;
  text-shadow:
      -0.2rem -0.2rem 1rem #aaf,
      0.2rem 0.2rem 1rem #aaf,
      -0.3rem -0.3rem 1rem #00f,
      0.3rem 0.3rem 1rem #00f,
      0 0 2rem #33a,
      0 0 4rem #33a,
      0 0 6rem #33a,
      0 0 8rem #33a,
      0 0 10rem #33a;
}

.App-neon.red {
  color: #fee;
  text-shadow:
      -0.2rem -0.2rem 1rem #faa,
      0.2rem 0.2rem 1rem #faa,
      -0.3rem -0.3rem 1rem #f00,
      0.3rem 0.3rem 1rem #f00,
      0 0 2rem #a33,
      0 0 4rem #a33,
      0 0 6rem #a33,
      0 0 8rem #a33,
      0 0 10rem #a33;
}

.App-aesthetic {
  background: url(/static/media/knoob-bg2.5cfb7f55.png);
}

.App-kl {
  background: url(/static/media/knoob-bg.ea6da51d.png);
}

.App-vending {
  background: url(/static/media/knoob-bg3.8888770f.png);
}

.App-kyoto {
  background: url(/static/media/knoob-bg4.56dd06e3.png);
}

.App-bg-fx {
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.App-bg-fx::before {
  content: '';
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-size: 6px 6px;
  position: absolute;
}

.App-bg-fx-grid::before {
  background: rgba(0, 0, 0, 0.5) url(/static/media/grid.37980be7.svg);
}

.App-bg-fx-matrix::before {
  background: rgba(0, 0, 0, 0.5) url(/static/media/matrix.453909cb.svg);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  
  .App-flicker {
    animation: App-neon-flicker 8s infinite alternate;
  }

  .App-flicker.red {
    animation: App-neon-flicker-red 8s infinite alternate;
  }

  .App-flicker2 {
    animation: App-neon-flicker 4s infinite alternate;
  }

  .App-flicker2.red {
    animation: App-neon-flicker-red 4s infinite alternate;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-neon-flicker {    
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      color: #eef;
      text-shadow:
          -0.2rem -0.2rem 1rem #aaf,
          0.2rem 0.2rem 1rem #aaf,
          -0.3rem -0.3rem 1rem #00f,
          0.3rem 0.3rem 1rem #00f,
          0 0 2rem #33a,
          0 0 4rem #33a,
          0 0 6rem #33a,
          0 0 8rem #33a,
          0 0 10rem #33a;
  }
  
  20%, 24%, 55% {
      color: #55a;
      text-shadow: 0 0 2rem #33a, 0 0 4rem #33a;
  }    
}

@keyframes App-neon-flicker-red {    
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      color: #fee;
      text-shadow:
          -0.2rem -0.2rem 1rem #faa,
          0.2rem 0.2rem 1rem #faa,
          -0.3rem -0.3rem 1rem #f00,
          0.3rem 0.3rem 1rem #f00,
          0 0 2rem #a33,
          0 0 4rem #a33,
          0 0 6rem #a33,
          0 0 8rem #a33,
          0 0 10rem #a33;
  }
  
  20%, 24%, 55% {
      color: #a55;
      text-shadow: 0 0 2rem #a33, 0 0 4rem #a33;
  }    
}


@font-face {
  font-family: Neon;
  src: local('Neon'), url(/static/media/Neon.ce0272ed.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  margin-bottom: 0 0 60px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


